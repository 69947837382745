class Siteheader {
    constructor (element, options) {
        const defaults = {
            initAttr: 'data-siteheader',
            hiddenClassname: 'siteheader--hidden',
            overlayClassname: 'siteheader--overlay',
            bodyClassname: 'body--has-siteheader-visible',
            focusedInClassname: 'siteheader--focus',
        };

        this.settings = Object.assign({}, defaults, options);

        this.$siteheader = element;
        this.$$checkboxes = this.$siteheader.querySelectorAll('input[type="checkbox"]');
    }

    initialize () {        
        this.handleVisibility();
        this.setEvents();
    }

    setEvents () {
        window.eventScroller.customFunctions.push(() => {
            this.handleVisibility();
            this.closeAllOpenedNavs();
        });

        this.$siteheader.addEventListener('focusin', () => {
            this.$siteheader.classList.add(this.settings.focusedInClassname);
        });
          
        this.$siteheader.addEventListener('focusout', () => {
            this.$siteheader.classList.remove(this.settings.focusedInClassname);
        });
    }

    closeAllOpenedNavs () {
        this.$$checkboxes.forEach($checkbox => {
            if ($checkbox.checked) {
                $checkbox.checked = false;
            }
        });
    }

    handleVisibility () {
        const siteheaderHeight = this.$siteheader.offsetHeight;

        if (window.scrollPosition > siteheaderHeight) {
            if (window.scrollDirection === 'down') {                
                this.toggleHeader(false, false);
            } else {
                this.toggleHeader(true, true);
            }
        } else {
            this.toggleHeader(true, false);
        }
    }

    toggleHeader (visible, shadow) {        
        if (visible === true) {
            if (this.$siteheader.classList.contains(this.settings.hiddenClassname)) {
                this.$siteheader.classList.remove(this.settings.hiddenClassname);

                if (shadow === true) {
                    if (this.$siteheader.classList.contains(this.settings.overlayClassname)) {
                        this.$siteheader.classList.remove(this.settings.overlayClassname);
                        document.body.classList.remove(this.settings.bodyClassname);
                    } else {
                        this.$siteheader.classList.add(this.settings.overlayClassname);
                        document.body.classList.add(this.settings.bodyClassname);
                    }
                }
            }
        } else {
            if (!this.$siteheader.classList.contains(this.settings.hiddenClassname)) {
                this.$siteheader.classList.add(this.settings.hiddenClassname);
            }
        }

        if (shadow === false) {
            if (this.$siteheader.classList.contains(this.settings.overlayClassname)) {
                this.$siteheader.classList.remove(this.settings.overlayClassname);
                document.body.classList.remove(this.settings.bodyClassname);
            }
        }
    }
}

export default Siteheader;

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        console.log('loaded');
        const $$roots = $context.querySelectorAll('[data-siteheader="root"]');
        $$roots.forEach($root => {
            const $rootAPI = new Siteheader($root);
            $rootAPI.initialize();
            window.siteheaderAPI = $rootAPI;
        });
    }
});
