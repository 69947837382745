import './accessibility.scss';

class Accessibility {
    constructor (options) {
        // Default options
        const defaults = {
            initAttr: 'data-accessibility',
            breakpoint: '1024'
        };

        // Merge settings
        this.settings = Object.assign({}, defaults, options);

        // DOM Elements
        this.$$checkboxes = document.querySelectorAll('input[type="checkbox"]');

        // Settings
        this.breakpoint = null;
        this.isKeyboardNavigationActive = false;
    }

    initialize () {
        this.setEvents();
    }

    /**
     * Toggles the checkbox state and updates the related content's visibility
     * and inert status based on the checkbox state (checked/unchecked).
     */
    toggleCheckboxState ($checkbox, $relatedContent, checked) {
        $checkbox.checked = checked;
        $checkbox.setAttribute('aria-expanded', checked);

        if (!checked) {
            $relatedContent.inert = true;
        }

        if (checked) {
            $relatedContent.inert = false;
        }
    }

    /**
     * Adds event listeners to a checkbox to handle toggling its state on keydown
     * (Enter key), and focusin/focusout events to reset the checkbox state if necessary.
     */
    handleCheckboxWithContent ($checkbox, $relatedContent) {
        let isFocusInsideChild = false;

        // Keydown listener for Enter key to toggle checkbox state
        $checkbox.addEventListener('keydown', (event) => {
            if (event.key === 'Enter') {
                this.toggleCheckboxState($checkbox, $relatedContent, !$checkbox.checked);
            }
        });

        // Monitor focus inside checkbox container
        if (this.breakpoint.matches) {
            $checkbox.parentNode.addEventListener('focusin', (event) => {
                if ($checkbox.parentNode.contains(event.target)) {
                    isFocusInsideChild = true;
                }
            });

            // If focus leaves the container, reset the checkbox state if necessary
            $checkbox.parentNode.addEventListener('focusout', (event) => {
                if (this.isKeyboardNavigationActive && !$checkbox.parentNode.contains(event.relatedTarget) && isFocusInsideChild && $checkbox.checked) {
                    this.toggleCheckboxState($checkbox, $relatedContent, false);
                    console.log('focusout');
                }

                // Reset focus status
                isFocusInsideChild = false;
                this.isKeyboardNavigationActive = false;
            });
        }
    }

    /**
     * Attaches event listeners to all checkboxes.
     * It links each checkbox with its corresponding content area and sets up listeners
     * for change and keydown events to control content visibility and inert status.
     */
    setEvents () {
        this.breakpoint = window.matchMedia('(min-width: ' + this.settings.breakpoint + 'px)');

        document.addEventListener('keydown', (event) => {
            if (event.key === 'Tab') {
                this.isKeyboardNavigationActive = true;
            }
        });

        this.$$checkboxes.forEach($checkbox => {
            const controlsSubContent = $checkbox.getAttribute('aria-controls');
            const $relatedContent = $checkbox.parentNode.querySelector(`#${controlsSubContent}`);

            if (controlsSubContent !== null && $relatedContent) {
                // Set event handlers for the checkbox and its associated content
                this.handleCheckboxWithContent($checkbox, $relatedContent);

                // Listen for change events on the checkbox and manage the related content
                $checkbox.addEventListener('change', () => {
                    this.toggleCheckboxState($checkbox, $relatedContent, $checkbox.checked);
                });
            }
        });

        window.resizeHandler.customFunctions.push(() => {
            this.breakpoint = window.matchMedia('(min-width: ' + this.settings.breakpoint + 'px)');
        });
    }
}

export { Accessibility };
