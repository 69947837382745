import './navigation.scss';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

class Navigation {
    constructor (element, options) {
        const defaults = {
            initAttr: 'data-nav',
            rootToggleAttr: 'rootToggle',
            childrenAttr: 'category',
            itemsAttr: 'items',
            scrollerAttr: 'scroller',
            groupAttr: 'data-nav-group',
            toggleAttr: 'toggle',
            closeAttr: 'close',
            levelAttr: 'data-nav-level',
            breakpoint: 1022
        };

        this.settings = Object.assign({}, defaults, options);

        this.$navigation = element;
        this.$navigationToggle = this.$navigation.querySelector('[' + this.settings.initAttr + '="' + this.settings.rootToggleAttr + '"]');
        this.$navigationScroller = this.$navigation.querySelector('[' + this.settings.initAttr + '="' + this.settings.scrollerAttr + '"]');
        this.$$navigationToggles = this.$navigation.querySelectorAll('[' + this.settings.initAttr + '="' + this.settings.toggleAttr + '"]');
        this.$$navigationMainItems = this.$navigation.querySelectorAll('[data-nav-level="1"], a.main-nav__item--level-1');
        this.$$navigationMainWithChildren = this.$navigation.querySelectorAll('[' + this.settings.initAttr + '="' + this.settings.childrenAttr + '"]');
        this.$body = document.querySelector('body');
        this.closeTimer = null;
        this.currentLevel = 1;
        this.breakpoint = window.matchMedia('(max-width: ' + this.settings.breakpoint + 'px)');
        
        this.initialize();
    }
    
    initialize () {         
        this.setEvents();
    }

    openSubNav ($toggle) {
        $toggle.checked = true;
        this.closeOthers($toggle);

        if (this.breakpoint.matches) {
            disableBodyScroll(this.$navigationScroller);
        }
    }
    
    toggleInertState ($subNav, inert) {
        $subNav.inert = inert;
    }

    setEvents () {
        const breakpoint = window.matchMedia('(min-width: ' + this.settings.breakpoint + 'px)');

        this.$navigation.addEventListener('change', (e) => {
            const $element = e.target;

            if ($element === this.$navigationToggle) {
                this.currentLevel = 1;

                if (this.breakpoint.matches) {
                    if (this.$navigationToggle.checked === true) {
                        disableBodyScroll(this.$navigationScroller);
                    } else {
                        this.clearAllSubnavs();
                        clearAllBodyScrollLocks();
                    }
                }
            }

            if ($element.getAttribute(this.settings.initAttr) === this.settings.toggleAttr) {
                this.$navigationScroller.scrollTo(0, 0);
                const elementLevel = parseInt($element.getAttribute(this.settings.levelAttr));

                if ($element.checked === true) {
                    this.currentLevel = elementLevel + 1;
                    this.closeOthers($element);
                } else {
                    this.currentLevel = elementLevel;
                    this.closeSubnav($element);
                }
            }
        });

        // open navigation on hover
        this.$$navigationMainWithChildren.forEach($navigationMainWithChildren => {
            const $subScroller = $navigationMainWithChildren.querySelector('[' + this.settings.initAttr + '="' + this.settings.subScrollerAttr + '"]');
            const $toggle = $navigationMainWithChildren.querySelector('[data-nav="toggle"]');
            const $subNav = $navigationMainWithChildren.querySelector('nav');

            if (breakpoint.matches) {
                $navigationMainWithChildren.addEventListener('mouseenter', () => {                
                    if ($toggle) {
                        this.openSubNav($toggle);
                    }

                    if ($subNav) {
                        this.toggleInertState($subNav, false);
                    }
                });
            }

            // close navigation if leaving root
            if (breakpoint.matches) {
                $navigationMainWithChildren.addEventListener('mouseleave', (e) => {                     
                    if (Array.from(this.$$navigationMainWithChildren).indexOf(e.relatedTarget) !== -1) {
                        $navigationMainWithChildren.parentNode.setAttribute('data-subnav-active', '');
                    } else {
                        $navigationMainWithChildren.parentNode.removeAttribute('data-subnav-active');
                    }

                    this.clearAllSubnavs();

                    if (this.breakpoint.matches) {
                        clearAllBodyScrollLocks($subScroller);
                    }

                    if ($subNav) {
                        this.toggleInertState($subNav, true);
                    } 
                });
            }
        });

        // close navigation on outside click
        document.addEventListener('click', (e) => {
            const $navigation = e.target.closest('[data-nav="root"]');
            if (!$navigation && this.$navigationToggle.checked === true) {
                this.closeMainnav();
            }
        });

        // close navigation on ESC key
        document.addEventListener('keydown', (e) => {
            let isEscape = false;
            if ('key' in e) {
                isEscape = (e.key === 'Escape' || e.key === 'Esc');
            } else {
                isEscape = (e.keyCode === 27);
            }
            if (isEscape && this.$navigationToggle.checked === true) {
                this.closeMainnav();
            }
        });

        // close navigation on resize (if no mobile device)
        window.resizeHandler.customFunctions.push(() => {
            this.breakpoint = window.matchMedia('(max-width: ' + this.settings.breakpoint + 'px)');
            clearAllBodyScrollLocks();

            if (!window.browser.android && !window.browser.ios && !window.browser.iphone) {
                this.closeMainnav();
            }
        });
        
        // close navigation on orientationchange
        window.addEventListener('orientationchange', () => {
            this.closeMainnav();            
        });
    }

    closeMainnav () {
        this.$navigationToggle.checked = false;
        this.currentLevel = 1;

        const navigationCloseEvent = new CustomEvent('change', {
            view: window,
            bubbles: true,
            cancelable: false
        });

        this.$navigationToggle.dispatchEvent(navigationCloseEvent);
    }

    closeSubnav ($currentToggle) {        
        const $currentNav = $currentToggle.closest('[' + this.settings.groupAttr + '="' + this.currentLevel + '"]');

        if ($currentNav) {
            const subLevel = this.currentLevel + 1;
            const $$subNavs = $currentNav.querySelectorAll('[' + this.settings.levelAttr + '="' + subLevel + '"]');

            $$subNavs.forEach($subNav => {
                $subNav.checked = false;   
            });
        }
    }

    closeOthers ($currentToggle) {        
        const $currentNav = $currentToggle.closest('[' + this.settings.initAttr + '="' + this.settings.itemsAttr + '"]');
        const $$siblings = $currentNav.querySelectorAll('[' + this.settings.initAttr + '="' + this.settings.toggleAttr + '"]');

        $$siblings.forEach($sibling => {
            if ($currentToggle !== $sibling) {
                $sibling.checked = false;
            }
        });
    }

    clearAllSubnavs () {
        for (let i = 0; i < this.$$navigationToggles.length; i++) {
            const $toggle = this.$$navigationToggles[i];

            if ($toggle.checked === true) {
                $toggle.checked = false;
            }
        }
    }
}

export default Navigation;

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context === document) {
        const $navigation = document.querySelector('[data-nav="root"]');
        if ($navigation) {
            const navigationAPI = new Navigation($navigation);
            $navigation.API = navigationAPI;
        }
    }
});
